import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, message } from 'antd';
import { injectGlobal } from 'styled-components';
import zh_CN from 'antd/lib/locale-provider/zh_CN';

import Router from './routes';
import WpsRouter from './routes/wpsRouter';
import './styles/overwrite.less';
import AppStore from './reducers/store';
import LoadingAnimation from './utils/loading-animation';
import themeManager from './utils/themeManager';
import { installWps } from '@/utils/wps'
import ChangeThemeLx from 'lxui/src/lib/changeTheme';
import { switchDomain } from './utils/OEM';
if (window.platform_1688) {
  // 1688没适配ik的域名, uc改成ik进都进不去, 前端强制根据参数修改。。。
  window.globalBrandToneName = 'blue';
  window.theme_style = 'blue';
}


// 开头加载动画
LoadingAnimation();
switchDomain();
if (module.hot) {
  module.hot.accept()
}
// 全局样式，不是个好方法
injectGlobal([`
  #container {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`]);

message.config({
  prefixCls: 'ucAntd-message'
  })

  // 更改lxui 主题色
function changeThemeColorLxui(list) {
  let themelink = document.getElementById('J_THEMESTYLEUC');
  if (!themelink) {
    themelink = document.createElement('style');
    themelink.setAttribute('type', 'text/css');
    themelink.setAttribute('id', 'J_THEMESTYLE');
    document.head.appendChild(themelink);
  }
  let inner = '';
  for (let i = 0; i < list.length; i++) {
    inner += list[i].key + ':' + list[i].value + ';';
  }
  inner = `.lxui{${inner}}`;
  themelink.innerText = themelink.innerText + inner;
}
async function renderApp () {
  // 确保wps环境下wps正确加载
  installWps();

  if(document.getElementById('container')) {
    ReactDOM.render(
      <ConfigProvider locale={zh_CN} prefixCls="ucAntd">
        <Provider store={AppStore}>
          <Router />
        </Provider>
      </ConfigProvider>,
      document.getElementById('container'))
  }
  if(document.getElementById('wps-contain')) {
    ReactDOM.render(
      <ConfigProvider locale={zh_CN} prefixCls="ucAntd">
        <Provider store={AppStore}>
          <WpsRouter />
        </Provider>
      </ConfigProvider>,
      document.getElementById('wps-contain')
    )
  }
}
// 加载主题
if (themeManager.getTheme() === themeManager.THEME_BLUE) {
  if (window.platform_1688) {
    import('./styles/blueTheme1688.less').then(renderApp).catch(renderApp);
  } else {
    import('./styles/blueTheme.less').then(renderApp).catch(renderApp);
  }
  ChangeThemeLx('blue');
  if (window.platform_1688) {
    changeThemeColorLxui([
      { key: '--lxy-primary-500', value: '0, 119, 255' },
    ]);
  } else {
    changeThemeColorLxui([]);
  }
} else if(themeManager.getTheme() === 'ding_blue'){
  import('./styles/blueThemeDingBlue.less').then(renderApp).catch(renderApp);
  ChangeThemeLx('blue', themeManager.getTheme() === 'ding_blue' ? { blue: { 500: '#007FFF', 550: '#007FFF',} } : null);
  // changeThemeColorLxui([
  //   { key: '--lxy-primary-500', value: '#007FFF' },
  // ]);
} else {
  import('./styles/orangeTheme.less').then(renderApp).catch(renderApp);
  ChangeThemeLx('orange');
  changeThemeColorLxui([]);
}
