import api from '@/apis/sso';
import {get, isEmpty, set} from 'lodash';
import docCookies from './cookies';
import {queryStringToMap, appTokenToPlatform, getParameterByName, getSearchString} from './qs';

let requestValue = null;
const checkIsOEM = async () => {
 if(requestValue) {
   return Promise.resolve(requestValue);
 };
 return api.queryIsOEM({
    host: location.href.includes('localhost') ? 'uc-test.ailitui.com' : location.host 
  }).then(res => {
    // 空对象 则 非OEM 
    const isOEM = !isEmpty(get(res, 'data'));
    requestValue = isOEM;
    return Promise.resolve(isOEM);
  }).catch(err => {
    console.log(err, 'err');
    return Promise.reject();
  })
};


const switchDomain = () => {
  const paramsMap = queryStringToMap();
  const [uid, redirectUri, ticket, type, appToken, platform, registered_way, accountToken,] =
  [
    paramsMap.get('uid'),
    paramsMap.get('redirectUri'),
    paramsMap.get('ticket'),
    paramsMap.get('type'),
    paramsMap.get('appToken'),
    paramsMap.get('platform'),
    paramsMap.get('registered_way'),
    paramsMap.get('accountToken')
  ];

  console.log(platform, appTokenToPlatform(appToken));
  console.log(platform || appTokenToPlatform(appToken));
  console.log(docCookies.getItem('TGCIK'), 'xxxxxxx');
  const TGC = docCookies.getItem(`TGC${platform || appTokenToPlatform(appToken)}`);
  const crmHostKey = `crm-host${TGC}`;
  const ucHostKey = `uc-host${TGC}`;
  const crmHostValue = docCookies.getItem(crmHostKey);
  const ucHostValue = docCookies.getItem(ucHostKey);
  console.log(crmHostValue, 'crmHostValue');
  if(crmHostValue) {
   set(AppConf, 'CRMHandoverHost.ik', crmHostValue);
   set(AppConf, 'CRMHandoverHost.lx', crmHostValue);
  };

  console.log(AppConf, 'AppConf')
};

export {
  checkIsOEM,
  switchDomain,
};

